import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<AnchorLinks>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<AnchorLink>`}</inlineCode>{` components are used together to display a list of anchor links for navigation within the page. Use these to link to `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<h3>`}</inlineCode>{` anywhere within your page. As long as the anchor link text and header text matches the link will work.`}</p>
      <p>{`For most pages, we recommend starting with a `}<inlineCode parentName="p">{`PageDescription`}</inlineCode>{` followed by `}<inlineCode parentName="p">{`AnchorLinks`}</inlineCode>{` if the content is long enough.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Normal</Title>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Link 1</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 2</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 3</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 4</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 5</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 6</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Link 7</AnchorLink>
    </AnchorLinks>
    <Title mdxType="Title">Small</Title>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Small link 1</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Small link 2</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Small link 3</AnchorLink>
    </AnchorLinks>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Normal</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/AnchorLinks/AnchorLinks.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/AnchorLinks",
        "path": "components/AnchorLinks/AnchorLinks.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/AnchorLinks"
      }}>{`<AnchorLinks>
  <AnchorLink>Link 1</AnchorLink>
  <AnchorLink>Link 2</AnchorLink>
  <AnchorLink>Link 3</AnchorLink>
  <AnchorLink>Link 4</AnchorLink>
  <AnchorLink>Link 5</AnchorLink>
  <AnchorLink>Link 6</AnchorLink>
  <AnchorLink>Link 7</AnchorLink>
</AnchorLinks>
`}</code></pre>
    <Title mdxType="Title">Small</Title>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/AnchorLinks/AnchorLinks.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/AnchorLinks",
        "path": "components/AnchorLinks/AnchorLinks.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/AnchorLinks"
      }}>{`<AnchorLinks small>
  <AnchorLink>Small link 1</AnchorLink>
  <AnchorLink>Small link 2</AnchorLink>
  <AnchorLink>Small link 3</AnchorLink>
</AnchorLinks>
`}</code></pre>
    <h3>{`Props`}</h3>
    <Title mdxType="Title">AnchorLinks</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display small font size`}</td>
        </tr>
      </tbody>
    </table>
    <Title mdxType="Title">AnchorLink</Title>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`props.children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`By default, the `}<inlineCode parentName="td">{`AnchorLink`}</inlineCode>{` slugifys the children you pass in. Use the to prop to override this target.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      